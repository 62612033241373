table {
    border-collapse: collapse;
    page-break-after: always;
    width: 100%;
}

.gridlines td {
    border: 1px dotted black
}

.gridlines th {
    border: 1px dotted black
}

.b {
    text-align: center
}

.e {
    text-align: center
}

.f {
    text-align: right
}

.inlineStr {
    text-align: left
}

.n {
    text-align: right
}

.s {
    text-align: left
}

td.style0 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style0 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style1 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style1 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style2 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style2 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style3 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style3 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style4 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style4 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style5 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C8C8C8
}

th.style5 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C8C8C8
}

td.style6 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style6 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style7 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 2px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

th.style7 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 2px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

td.style8 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C8C8C8
}

th.style8 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C8C8C8
}

td.style9 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style9 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style10 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style10 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style11 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style11 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style12 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style12 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style13 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: none #000000;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style13 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: none #000000;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style14 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: none #000000;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style14 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: none #000000;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style15 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style15 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style16 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style16 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style17 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style17 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style18 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style18 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style19 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style19 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style20 {
    vertical-align: bottom;
    border-bottom: 2px solid #000000 !important;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style20 {
    vertical-align: bottom;
    border-bottom: 2px solid #000000 !important;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style21 {
    vertical-align: bottom;
    border-bottom: 2px solid #000000 !important;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style21 {
    vertical-align: bottom;
    border-bottom: 2px solid #000000 !important;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style22 {
    vertical-align: bottom;
    border-bottom: 2px solid #000000 !important;
    border-top: none #000000;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style22 {
    vertical-align: bottom;
    border-bottom: 2px solid #000000 !important;
    border-top: none #000000;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style23 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: 2px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style23 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: 2px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style24 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: 2px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style24 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: 2px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style25 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: 2px solid #000000 !important;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style25 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: 2px solid #000000 !important;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style26 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style26 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style27 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style27 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style28 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style28 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style29 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: none #000000;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style29 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: none #000000;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style30 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style30 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style31 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: none #000000;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style31 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: none #000000;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style32 {
    vertical-align: middle;
    text-align: center;
    border-bottom: none #000000;
    border-top: 2px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 16pt;
    background-color: white
}

th.style32 {
    vertical-align: middle;
    text-align: center;
    border-bottom: none #000000;
    border-top: 2px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 16pt;
    background-color: white
}

td.style33 {
    vertical-align: middle;
    text-align: center;
    border-bottom: none #000000;
    border-top: 2px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style33 {
    vertical-align: middle;
    text-align: center;
    border-bottom: none #000000;
    border-top: 2px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style34 {
    vertical-align: middle;
    text-align: center;
    border-bottom: none #000000;
    border-top: 2px solid #000000 !important;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style34 {
    vertical-align: middle;
    text-align: center;
    border-bottom: none #000000;
    border-top: 2px solid #000000 !important;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style35 {
    vertical-align: middle;
    text-align: center;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style35 {
    vertical-align: middle;
    text-align: center;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style36 {
    vertical-align: middle;
    text-align: center;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style36 {
    vertical-align: middle;
    text-align: center;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style37 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style37 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style38 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: none #000000;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style38 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: none #000000;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style39 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

th.style39 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

td.style40 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

th.style40 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

td.style41 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

th.style41 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

td.style42 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

th.style42 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

td.style43 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

th.style43 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

td.style44 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style44 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style45 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style45 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style46 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style46 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style47 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: white
}

th.style47 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: white
}

td.style48 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: white
}

th.style48 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: white
}

td.style49 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: white
}

th.style49 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: white
}

td.style50 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C8C8C8
}

th.style50 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C8C8C8
}

td.style51 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C8C8C8
}

th.style51 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C8C8C8
}

td.style52 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

th.style52 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

td.style53 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

th.style53 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

td.style54 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

th.style54 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

td.style55 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

th.style55 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

td.style56 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

th.style56 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

td.style57 {
    vertical-align: bottom;
    text-align: left;
    padding-left: 0px;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style57 {
    vertical-align: bottom;
    text-align: left;
    padding-left: 0px;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style58 {
    vertical-align: bottom;
    text-align: left;
    padding-left: 0px;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style58 {
    vertical-align: bottom;
    text-align: left;
    padding-left: 0px;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style59 {
    vertical-align: bottom;
    text-align: left;
    padding-left: 0px;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style59 {
    vertical-align: bottom;
    text-align: left;
    padding-left: 0px;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style60 {
    vertical-align: bottom;
    text-align: left;
    padding-left: 0px;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style60 {
    vertical-align: bottom;
    text-align: left;
    padding-left: 0px;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style61 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C8C8C8
}

th.style61 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C8C8C8
}

td.style62 {
    vertical-align: bottom;
    text-align: left;
    padding-left: 0px;
    border-bottom: none #000000;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style62 {
    vertical-align: bottom;
    text-align: left;
    padding-left: 0px;
    border-bottom: none #000000;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style63 {
    vertical-align: bottom;
    text-align: left;
    padding-left: 0px;
    border-bottom: none #000000;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style63 {
    vertical-align: bottom;
    text-align: left;
    padding-left: 0px;
    border-bottom: none #000000;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style64 {
    vertical-align: bottom;
    text-align: left;
    padding-left: 0px;
    border-bottom: none #000000;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style64 {
    vertical-align: bottom;
    text-align: left;
    padding-left: 0px;
    border-bottom: none #000000;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style65 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style65 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style66 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style66 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style67 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style67 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style68 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

th.style68 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

td.style69 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

th.style69 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

td.style70 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

th.style70 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

td.style71 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Arial';
    font-size: 8pt;
    background-color: #C0C0C0
}

th.style71 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Arial';
    font-size: 8pt;
    background-color: #C0C0C0
}

td.style72 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Arial';
    font-size: 8pt;
    background-color: #C0C0C0
}

th.style72 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Arial';
    font-size: 8pt;
    background-color: #C0C0C0
}

td.style73 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Arial';
    font-size: 8pt;
    background-color: #C0C0C0
}

th.style73 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Arial';
    font-size: 8pt;
    background-color: #C0C0C0
}

td.style74 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style74 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style75 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: none #000000;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style75 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: none #000000;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style76 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: none #000000;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style76 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: none #000000;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style77 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style77 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style78 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style78 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style79 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

th.style79 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

td.style80 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

th.style80 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: #C0C0C0
}

td.style81 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style81 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style82 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 2px solid #000000 !important;
    border-top: none #000000;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style82 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 2px solid #000000 !important;
    border-top: none #000000;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style83 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 2px solid #000000 !important;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style83 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 2px solid #000000 !important;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style84 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style84 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style85 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

th.style85 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C0C0C0
}

td.style86 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C8C8C8
}

th.style86 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C8C8C8
}

td.style87 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C8C8C8
}

th.style87 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C8C8C8
}

td.style88 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C8C8C8
}

th.style88 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C8C8C8
}

td.style89 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C8C8C8
}

th.style89 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C8C8C8
}

td.style90 {
    vertical-align: bottom;
    text-align: left;
    padding-left: 0px;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style90 {
    vertical-align: bottom;
    text-align: left;
    padding-left: 0px;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style91 {
    vertical-align: bottom;
    text-align: left;
    padding-left: 0px;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style91 {
    vertical-align: bottom;
    text-align: left;
    padding-left: 0px;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style92 {
    vertical-align: bottom;
    text-align: left;
    padding-left: 0px;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style92 {
    vertical-align: bottom;
    text-align: left;
    padding-left: 0px;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style93 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C8C8C8
}

th.style93 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C8C8C8
}

td.style94 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C8C8C8
}

th.style94 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: #C8C8C8
}

td.style95 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: white
}

th.style95 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: white
}

td.style96 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: white
}

th.style96 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Arial';
    font-size: 10pt;
    background-color: white
}

td.style97 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style97 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style98 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style98 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style99 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style99 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style100 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style100 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style101 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style101 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style102 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style102 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style103 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style103 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style104 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style104 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style105 {
    vertical-align: bottom;
    text-align: left;
    padding-left: 0px;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style105 {
    vertical-align: bottom;
    text-align: left;
    padding-left: 0px;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style106 {
    vertical-align: bottom;
    text-align: left;
    padding-left: 0px;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style106 {
    vertical-align: bottom;
    text-align: left;
    padding-left: 0px;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style107 {
    vertical-align: bottom;
    text-align: left;
    padding-left: 0px;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style107 {
    vertical-align: bottom;
    text-align: left;
    padding-left: 0px;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style108 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style108 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style109 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style109 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style110 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style110 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style111 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style111 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style112 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style112 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style113 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style113 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style114 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style114 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style115 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style115 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style116 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style116 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style117 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style117 {
    vertical-align: bottom;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style118 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style118 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style119 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style119 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 2px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style120 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style120 {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 2px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style121 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style121 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style122 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style122 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style123 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style123 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

td.style124 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}

th.style124 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: none #000000;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
}